const nav = document.querySelector("nav")
const user = document.querySelector("nav .user")

let prevScrollPos = window.scrollY
let currentScrollPos

const maxHeight = window.innerHeight / 2

window.onscroll = () => {
    currentScrollPos = window.scrollY

    if (prevScrollPos > currentScrollPos && currentScrollPos < maxHeight) {
        // Scrolling up, show nav
        nav.classList.remove("hidden")
    } else {
        // Scrolling down or at the top, hide nav
        nav.classList.add("hidden")
    }

    prevScrollPos = currentScrollPos
}

if (user) {
    user.innerHTML += `
    <div class="user-options">
        <a href="/access/user.php" target="_blank"><button class="primary">Settings</button></a>
        <a href="/access/logout.php"><button class="negative">Logout</button></a>
    </div>
    `
    user.onclick = (e) => {
        e.stopPropagation()
        user.classList.toggle("active")
    }
    document.onclick = () => {
        user.classList.remove("active")
    }
}
